<template>
  <base-section id="we-help-your-success">
    <base-section-heading title="OUR BRANDS" outlined />
    <!-- APPONE -->
    <v-container class="con1">
      <v-row>
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            data-aos-duration="1000"
            contain
            src="@/assets/wplunkket.jpg"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.wplunket.ie/"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos-duration="1000"
            data-aos="flip-up"
            contain
            src="@/assets/thelockkeeper.jpg"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.thelockkeeper.ie/"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            data-aos-duration="1000"
            contain
            src="@/assets/mulliganandhaines.jpg"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.mulliganandhaines.ie/"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            data-aos-duration="1000"
            contain
            src="@/assets/mooneys.jpg"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.mooneysbar.ie/"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
      </v-row>
      <v-row class=" d-flex justify-content-center">
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            data-aos-duration="1000"
            contain
            src="@/assets/mulliganhotelNEW.jpg"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.mulliganhotel.ie/"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            contain
            src="@/assets/ablhotelNEW.jpg"
            data-aos-duration="1000"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.wplunket.ie/hotel"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            contain
            src="@/assets/thesackville.jpg"
            data-aos-duration="1000"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="http://thesackvillelounge.ie/"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
      </v-row>
      <v-row class=" d-flex justify-content-center">
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            data-aos-duration="1000"
            contain
            src="@/assets/thejames.png"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.dalysofedenquay.ie/jamesconnolly"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            contain
            src="@/assets/danby.png"
            data-aos-duration="1000"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.danbyhotel.ie/"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
        <v-col cols="6" md="3">
          <v-img
            class="elevation-3"
            data-aos="flip-up"
            contain
            src="@/assets/dalys.png"
            data-aos-duration="1000"
            color="grey lighten-1"
            tile
          />
          <base-btn
            href="https://www.dalysofedenquay.ie/"
            style="width: 100%;"
            data-aos="flip-up"
          >
            VIEW WEBSITE
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- END -->
  </base-section>
</template>

<script>
export default {
  name: "Brands",

  data() {
    return {
      appones: [
        {
          src: require("@/assets/m1shad.png")
        },
        {
          src: require("@/assets/m1shad.png")
        },
        {
          src: require("@/assets/m1shad.png")
        },
        {
          src: require("@/assets/m1shad.png")
        }
      ]
    };
  }
};
</script>
